import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AFRAME from "aframe";
import { Entity } from "aframe-react";
//import info from "../assets/info.png";
import info from "../assets/plus.svg";
import gafas_white from "../assets/gafas_white.png";
import video from "../assets/video.jpg";
import image from "../assets/image-icon.png";
//import gafasVR from "../assets/vr_icon.svg";
import "./controles.css";
import LoadingSpin from "./loadingSpin";
import PopUpMsg from "./popUpMsg";
import OpenProject from "./openProject";
import EscenasMenu from "./escenasMenu";
import { Alert, Button } from "antd";
import "animate.css";
import MenuComponent from "./MenuComponent";
import ReactGA from "react-ga4";
import MenuOtras from "./MenuOtras";
import Visitas from "./Visitas";
import MenuRedes from "./MenuRedes";
import MenuEscenas from "./MenuEscenas";
import MenuEnlaces from "./MenuEnlaces";
//import CardCar from "./CardCar";
import { ANALYTICS_EVENT_TYPE } from "../core/constants/analitics-constants";
import apiService from "../services/apiServices";
//import Otra360VR from "./player-components/Otra360VR";
//import exitPng from "../assets/exit.png";
import { Helmet } from "react-helmet";

//recursos para portal
import portal from "../assets/portal/portal.png";

import Otra360VR from "./player-components/Otra360VR";
import { ModalShare } from "./share-components/modalShare";
//import ModalConfirmationVR from "../core/componentsVR/ModalConfirmationVR";
//import { type } from "@testing-library/user-event/dist/types/utility";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import navigationAction from "./automatic-tour/navigationAction";
// import navigationAction from "./automatic-tour/navigationAction";

const driverObj = driver({
  showProgress: true,
  steps: [
    {     
      popover: {
        title: "Guia Inicial",
        description: `Bienvenido a la experiencia 360.Esta guía te ayudará a navegar por la experiencia y a aprovechar al máximo tus sentidos`,
        side: "left",
        align: "start",
      },
    },
    {
      element: ".first-step", 
      popover: {
        title: "Título de la Experiencia 360",
        description: "",
      },
    },
    {
      element: ".second-step",
      popover: {
        title: "Botón al canal de compras",
        description: "Descubre los productos que aparecen en la experiencia 360 y cómpralos directamente desde aquí.",
        side: "bottom", 
        align: "start",
      },
    },
    {
      element: ".third-step",
      popover: {
        title: "Botón al ambiente VR",
        description: "Sumérgete en la experiencia 360 en realidad virtual para una experiencia aún más inmersiva.",
        side: "bottom",
        align: "start",
      },
    },
    {
      element: ".fourth-step",
      popover: {
        title: "Botón compartir en redes sociales", 
        description: "Comparte la experiencia 360 con tus amigos y familiares en las redes sociales.",
        side: "left",
        align: "start",
      },
    },
    {
      element: ".fifth-step",
      popover: {
        title: "Botón de experiencias relacionadas",
        description: "Explora otras experiencias 360 similares y descubre nuevos mundos.",
        side: "top",
        align: "start", 
      },
    },
    {
      element: ".sixth-step",
      popover: {
        title: "Menú de las escenas de la experiencia 360",
        description: "Navega por las diferentes escenas de la experiencia 360 y explora cada rincón.",
        side: "right",
        align: "start",
      },
    },
    {
      element: ".seventh-step", 
      popover: {
        title: "Menú de herramientas",
        description: "Utiliza las herramientas de zoom, ampliar y vista VR para personalizar tu experiencia.",
        side: "right",
        align: "start",
      },
    }
  ],
  nextBtnText: "Siguiente",
  prevBtnText: "Anterior", 
  doneBtnText: "Entendido",
});

require("aframe-look-at-component");

export default function Player360() {
  const [open, setOpen] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [popUpTitulo, setPopUpTitulo] = useState("");
  const [popUpContenido, setPopUpContenido] = useState(null);
  const [isMobileXp] = useState(AFRAME.utils.device.isMobile());
  const [isVR, setIsVR] = useState(false);

  //state que maneja el verdadero modo VR
  const [isVRMode, setIsVRMode] = useState(false);

  //posicion x para los portales
  const positionX = 0;

  //useState para el titulo del index.html
  const [tituloHtml, setTituloHtml] = useState("Griftin");

  const [escenaIndex, setEscenaIndex] = useState(0);
  const [proyecto, setProyecto] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [mobileVideoSrc, setMobileVideoSrc] = useState("");
  const [mobilePlayerPos, setMobilePlayerPos] = useState({ x: 0, y: 0, z: 1 });
  const [VRPopUpImage, setVRPopUpImage] = useState(null);
  const [timeOutClick, setTimeOutClick] = useState(null);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [animateEnabled, setAnimateEnabled] = useState(true);
  const [urlLink, setUrlLink] = useState("");
  const [showAlertLink, setShowAlertLink] = useState(false);
  const [showMenuFloat, setShowMenuFloat] = useState(true);
  const [desplegarMenu, setDesplegarMenu] = useState(false);
  //state que maneja el ocultamiento de la card de menu de otras segun click en pantalla
  const [desplegarCard, setDesplegarCard] = useState(false);
  const menuEnlacesOtrasRef = useRef(null);

  //boolean que maneja el modo de origen de los datos de la Experiencia
  //true: drive, false: API
  const [modo, setModo] = useState(true);

  //variables para analitica de permanencia
  const [spentTime, setSpentTime] = useState(0);
  let startTime;

  //state que maneja la cantidad de visitas de una pagina
  const [countVisits, setCountVisits] = useState(0);

  //state que captura las interacciones que luego seran enviadas a la API
  const [interactions, setInteractions] = useState({
    share_button: 0,
    cart_button: 0,
    scene_navigation_button: 0,
    experience_navigation_button: 0,
    video_button: 0,
    info_button: 0,
    image_button: 0,
    zoom_plus_button: 0,
    zoom_minus_button: 0,
    fullscreen_button: 0,
    carrousel_scenes_button: 0,
  });

  //state que maneja la visibilidad del modalVR de confirmacion
  const [showModalVR, setShowModalVR] = useState(false);

  const [titulo360Value, setTitulo360Value] = useState(" ");
  const [alertErrorMessage, setAlertErrorMessage] = useState({
    titulo: "",
    mensaje: "",
  });

  const [redesState, setRedesState] = useState({
    facebook: "",
    twitter: "",
    youtube: "",
  });

  const [linksDataState, setLinksData] = useState([]);

  const location = useParams();
  const cielo = useRef(null);
  const escena = useRef(null);
  const camara = useRef(null);
  const cursor = useRef(null);
  const vrbutton = useRef(null);
  const videoControl = useRef(null);
  const iframe = useRef(null);
  var distanciaTouch = 0;
  var touchPos = { x: 0, y: 0 };

  //  const ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

  //state que controla que el post de pagina visitada se ejecute una vez
  const [pageVisited, setPageVisited] = useState(false);

  const getTipo = (e) => {
    switch (e.tipo) {
      case 0:
        return "video-button";
      case 1:
        return "msg-button";
      case 2:
        return "view-button";
      case 3:
        return "image-button";
      default:
        return null;
    }
  };

  //registro de evento de navegacion a url externa : Fernando
  if (!AFRAME.components["navigate-out"]) {
    AFRAME.registerComponent("navigate-out", {
      schema: { type: "string" }, // URL de destino

      init: function () {
        const url = this.data;
        this.el.addEventListener("click", () => {
          //console.log("Navegando a la URL:", url);
          window.location.href = url;
        });
      },
    });
  }

  // if (!AFRAME.components["exit-inmersion-VR"]) {
  //   AFRAME.registerComponent("exit-inmersion-VR", {
  //     schema: { type: "string" },
  //     init: function () {
  //       const dato = this.data;
  //       this.el.addEventListener("click", () => {
  //         console.log("Saliendo de la inmersion", dato);
  //         //window.location.href = "/";
  //         //  escena.current.exitVR();
  //         setFullscreen();
  //       });
  //     },
  //   });
  // }

  useEffect(() => {
    //agreguemos a la escena el evento necesario para que sepa cuando esta en modo VR o no
    const sceneEl = document.querySelector("a-scene");

    const enterModeVR = () => {
      setIsVRMode(true);
    };

    const exitModeVR = () => {
      setIsVRMode(false);
    };

    sceneEl.addEventListener("enter-vr", enterModeVR);
    sceneEl.addEventListener("exit-vr", exitModeVR);

    //const interval = setInterval(() => {
    //if (proyecto?.escenas && cielo.current && !AFRAME.components["cursor-listener"]) {
    if (cielo.current && !AFRAME.components["to-scene-1"]) {
      AFRAME.registerComponent("to-scene-1", {
        init: function () {
          //let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");
              //setEscenaOnEventVR(indiceEscena);
              setEscenaOnEventVR(0);
            }
          });
        },
      });
    }
    if (cielo.current && !AFRAME.components["to-scene-2"]) {
      AFRAME.registerComponent("to-scene-2", {
        init: function () {
          let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at 2: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");
              //setEscenaOnEventVR(indiceEscena);
              setEscenaOnEventVR(1);
            }
          });
        },
      });
    }

    if (cielo.current && !AFRAME.components["to-scene-3"]) {
      AFRAME.registerComponent("to-scene-3", {
        init: function () {
          let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at 3: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");
              //setEscenaOnEventVR(indiceEscena);
              setEscenaOnEventVR(2);
            }
          });
        },
      });
    }

    if (cielo.current && !AFRAME.components["to-scene-4"]) {
      AFRAME.registerComponent("to-scene-4", {
        init: function () {
          let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at 4: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");
              //setEscenaOnEventVR(indiceEscena);
              setEscenaOnEventVR(3);
            }
          });
        },
      });
    }

    if (cielo.current && !AFRAME.components["to-scene-5"]) {
      console.log("PROYECTO ADENTRO DEL REGISTRO DE EVENTO 5!!!!", proyecto);

      AFRAME.registerComponent("to-scene-5", {
        init: function () {
          let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at 5: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");
              //setEscenaOnEventVR(indiceEscena);
              setEscenaOnEventVR(4);
            }
          });
        },
      });
    }

    if (cielo.current && !AFRAME.components["to-scene-6"]) {
      AFRAME.registerComponent("to-scene-6", {
        init: function () {
          let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at 5: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");
              //setEscenaOnEventVR(indiceEscena);
              setEscenaOnEventVR(5);
            }
          });
        },
      });
    }

    if (cielo.current && !AFRAME.components["to-scene-7"]) {
      AFRAME.registerComponent("to-scene-7", {
        init: function () {
          let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at 5: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");
              //setEscenaOnEventVR(indiceEscena);
              setEscenaOnEventVR(6);
            }
          });
        },
      });
    }

    if (cielo.current && !AFRAME.components["to-scene-8"]) {
      AFRAME.registerComponent("to-scene-8", {
        init: function () {
          let lastIndex = -1;
          // const COLORS = ["red", "green", "blue"];

          this.el.addEventListener("click", function (evt) {
            // lastIndex = (lastIndex + 1) % COLORS.length;
            // this.setAttribute("material", "color", COLORS[lastIndex]);
            // console.log("I was clicked at 5: ", evt.detail.intersection.point);

            if (cielo.current) {
              //cielo.current.setAttribute("material", "color", COLORS[lastIndex]);
              // cielo.current.setAttribute("material", "src", "https://images.pexels.com/photos/17325269/pexels-photo-17325269/free-photo-of-molinos-de-viento.jpeg?auto=compress&cs=tinysrgb&w=600");
              //cielo.current.setAttribute("material", "src", skySrc); // Usa el valor actual del ref
              if (playing) return;
              cielo.current.emit("fadein");              
              setEscenaOnEventVR(7);
            }
          });
        },
      });
    }
    //}, 100); // Revisa cada 100 ms hasta que las condiciones se cumplan.

    // Limpia el intervalo si el componente se desmonta antes de cumplir las condiciones.
    //  return () => clearInterval(interval);
    // Limpia los event listeners cuando el componente se desmonta
    return () => {
      sceneEl.removeEventListener("enter-vr", enterModeVR);
      sceneEl.removeEventListener("exit-vr", exitModeVR);
    };
  }, []);

  // useEffect(() => {
  //   if (proyecto) {
  //     for (let i = 0; i < proyecto.escenas.length; i++) {
  //       const element = document.getElementById(`link${i + 1}`);
  //       if (element) {
  //         element.setAttribute("visible", "true"); // Cambia el atributo "visible" a true en A-Frame
  //         const label = document.getElementById(`label${i + 1}`);
  //         if (label) {
  //           label.setAttribute("value", proyecto.escenas[i].titulo); // Cambia el atributo "visible" a true en A-Frame
  //         }
  //       } else {
  //         console.warn(`Elemento con ID link${i + 1} no encontrado.`);
  //       }
  //     }
  //   }
  // }, [proyecto]);

  const setEscenaOnEventVR = (indice) => {
    setInteractions((prevInteractions) => ({
      ...prevInteractions,
      scene_navigation_button: prevInteractions.scene_navigation_button + 1,
    }));

    let controls = camara.current.components["look-controls"];
    setEscenaIndex(indice);
  };

  const setEscena = (escenaTitulo, p) => {
    cielo.current.emit("fadeout");

    // Usar un intervalo para verificar cuándo controls está disponible
    const intervalId = setInterval(() => {
      let controls = camara.current.components["look-controls"];
      console.log("CONTROLS", controls);

      if (controls) {
        clearInterval(intervalId); // Detenemos el intervalo cuando controls esté disponible
        let indice = p.escenas.findIndex((x) => x.titulo === escenaTitulo);

        if (indice < 0) indice = 0;

        setEscenaIndex(indice);

        let valorX =
          ((p.escenas[indice].inicio ? p.escenas[indice].inicio.x : 0) *
            Math.PI) /
          180;
        let valorY =
          (((p.escenas[indice].inicio ? p.escenas[indice].inicio.y : 0) *
            Math.PI) /
            180) *
          -1;

        controls.pitchObject.rotation.x = valorX;
        controls.yawObject.rotation.y = valorY;

        cielo.current.emit("fadein");
      }
    }, 100); // Verifica cada 100ms si los controles están disponibles
  };

  const setVR = () => {
    escena.current.enterVR();
  };

  const setFullscreen = () => {
    if (!document.fullscreenElement)
      document.documentElement.requestFullscreen();
    else document.exitFullscreen();
  };

  const zoomIn = (zoomin) => {
    let objCamara = camara.current.getAttribute("camera", "zoom");
    objCamara.zoom += 0.1 * (zoomin ? 1 : -1);
    if (objCamara.zoom <= 1) objCamara.zoom = 1;
    if (objCamara.zoom >= 5) objCamara.zoom = 5;

    camara.current.setAttribute("camera", "zoom", objCamara.zoom);
  };

  const handleCardRelacionadas = (event) => {
    // console.log(event.target)
    // console.log("REFFFFFF", menuEnlacesOtrasRef.current)
    // console.log("contain event", !menuEnlacesOtrasRef.current.contains(event.target))
    if (
      menuEnlacesOtrasRef.current &&
      !menuEnlacesOtrasRef.current.contains(event.target)
    ) {
      // console.log("CLICKEANDO AFUERA!!");
      // console.log(desplegarCard);
      setDesplegarCard((prev) => false);
    } else {
      // console.log("CLICKEANDO ADENTRO!!");
      setDesplegarCard((prev) => true);
    }
  };

  //metodo que dispara el carrito
  const handleClickLinkButton = async (e) => {
    if (urlLink !== undefined && urlLink !== "") {
      ReactGA.event({
        category: "User",
        action: "onLinkClick",
      });
      //lugar adonde voy a disparar la carga de evento carrito para analiticas
      try {
        const data = {
          id: proyecto.id,
          userId: proyecto.userId,
          additional: {
            link: urlLink,
          },
        };
        if (!modo) {
          await apiService.trackEvent({
            type: ANALYTICS_EVENT_TYPE.CART_VISIT,
            experienceData: data,
          });
          //alimento el objeto de interacciones
          setInteractions((prevInteractions) => ({
            ...prevInteractions,
            cart_button: prevInteractions.cart_button + 1,
          }));
        }
      } catch (error) {
        console.error(error.message);
      }

      window.open(urlLink, "_blank");
    } else {
      setAlertErrorMessage({
        titulo: "Link Inexistente",
        mensaje:
          "Configure el enlace de la Experiencia 360 con su sitio web o su carrito de compras.",
      });
      setShowAlertLink(true);
      setTimeout(() => {
        setShowAlertLink(false);
      }, 2000);
    }
  };

  const [showModalShare, setShowModalShare] = useState(false);

  const handleShareButton = async () => {
    try {
      const data = {
        id: proyecto.id,
        userId: proyecto.userId,
        additional: {
          link: window.location.href,
          escena: proyecto.escenas[escenaIndex].titulo,
        },
      };
      if (!modo) {
        await apiService.trackEvent({
          type: ANALYTICS_EVENT_TYPE.SHARE_EXPERIENCE,
          experienceData: data,
        });
        //alimento el objeto de interacciones
        setInteractions((prevInteractions) => ({
          ...prevInteractions,
          share_button: prevInteractions.share_button + 1,
        }));
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowModalShare(true);
    }
  };

  const handleNavigateRelated = (urlTarget) => {
    if (urlTarget !== undefined && urlTarget !== "") {
      ReactGA.event({
        category: "User",
        action: "onLinkClick",
      });
      window.open(urlTarget, "_blank");
    } else {
      setAlertErrorMessage({
        titulo: "Link Inexistente",
        mensaje:
          "Configure el enlace de la Experiencia 360 con su sitio web o su carrito de compras.",
      });
      setShowAlertLink(true);
      setTimeout(() => {
        setShowAlertLink(false);
      }, 2000);
    }
  };

  const handleClickPlusButton = (e) => {
    zoomIn(true);
    //capturemos la metrica
    setInteractions((prevInteractions) => ({
      ...prevInteractions,
      zoom_plus_button: prevInteractions.zoom_plus_button + 1,
    }));
  };

  const handleClickMinusButton = (e) => {
    zoomIn(false);
    //capturemos la metrica
    setInteractions((prevInteractions) => ({
      ...prevInteractions,
      zoom_minus_button: prevInteractions.zoom_minus_button + 1,
    }));
  };

  const handleClickFullScreenButton = (e) => {
    setFullscreen();
    //capturemos la metrica
    setInteractions((prevInteractions) => ({
      ...prevInteractions,
      fullscreen_button: prevInteractions.fullscreen_button + 1,
    }));
  };

  const handleClickScenesButton = (e) => {
    viewScenasShortcut();
    //capturemos la metrica
    setInteractions((prevInteractions) => ({
      ...prevInteractions,
      carrousel_scenes_button: prevInteractions.carrousel_scenes_button + 1,
    }));
  };

  const handleClickOptionButton = (key_word) => {
    switch (key_word) {
      case "enlace_1_key":
        break;

      case "animation_key":
        setAnimateEnabled(!animateEnabled);
        break;
      case "twitter_key":
        if (redesState?.twitter !== undefined && redesState?.twitter !== "") {
          window.open(redesState.twitter, "_blank");
        } else {
          setAlertErrorMessage({
            titulo: "Twitter Inexistente",
            mensaje: "Configure el enlace a su plataforma de Twitter.",
          });
          setShowAlertLink(true);
          setTimeout(() => {
            setShowAlertLink(false);
          }, 2000);
        }
        break;
      case "youtube_key":
        if (redesState?.youtube !== undefined && redesState?.youtube !== "") {
          window.open(redesState.youtube, "_blank");
        } else {
          setAlertErrorMessage({
            titulo: "Youtube Inexistente",
            mensaje: "Configure el enlace a su plataforma de Youtube.",
          });
          setShowAlertLink(true);
          setTimeout(() => {
            setShowAlertLink(false);
          }, 2000);
        }
        break;
      case "facebook_key":
        if (redesState?.facebook !== undefined && redesState?.facebook !== "") {
          window.open(redesState.facebook, "_blank");
        } else {
          setAlertErrorMessage({
            titulo: "Facebook Inexistente",
            mensaje: "Configure el enlace a su plataforma de Facebook.",
          });
          setShowAlertLink(true);
          setTimeout(() => {
            setShowAlertLink(false);
          }, 2000);
        }
        break;

      default:
        break;
    }
  };

  const setVRPopUpPosition = (e, elemento = "player") => {
    let pos = camara.current.getAttribute("position");
    let rot = camara.current.getAttribute("rotation");
    console.log(pos, rot);

    const distance = 30;
    const theta = (rot.y * Math.PI) / 180;
    const planeX = pos.x - distance * Math.sin(theta);
    const planeZ = pos.z - distance * Math.cos(theta);
    let position = document.getElementById(elemento).getAttribute("position");
    position.x = planeX;
    position.y = pos.y;
    position.z = planeZ;
    console.log(position);
    document.getElementById(elemento).setAttribute("position", position);
  };

  const getFunction = (e) => {
    switch (e.tipo) {
      case 0:
        return () => {
          if (playing) return;
          if (isVRMode) return;
          //capturemos la metrica
          setInteractions((prevInteractions) => ({
            ...prevInteractions,
            video_button: prevInteractions.video_button + 1,
          }));
          if (!isVR) {
            setPopUpTitulo(e.nombre);
            let videoURL =
              e.video +
              (e.video && e.video.includes("?") ? "&" : "?") +
              "autoplay=1";
            if (iframe.current) iframe.current.src = videoURL;
            setPopUpContenido(
              <iframe
                ref={!iframe.current ? iframe : null}
                src={videoURL}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                width="100%"
                height="350vh"
              />
            );

            setOpen(true);
            console.log(e);
          } else {
            setPopUpTitulo(e.nombre);
            setMobileVideoSrc(e.video);
            console.log(e.position);
            setVRPopUpPosition(e);
            videoControl.current.play();
            setPlaying(true);
          }
        };
      case 1:
        return () => {
          //capturemos la metrica
          setInteractions((prevInteractions) => ({
            ...prevInteractions,
            info_button: prevInteractions.info_button + 1,
          }));
          if (playing) return;
          setPopUpTitulo(e.titulo);
          setPopUpContenido(e.texto);
          if (!isVR) {
            setOpen(true);
            console.log(e);
          } else {
            setVRPopUpPosition(e);
            setPlaying(true);
            setTimeout(() => {
              setPlaying(false); // Desactiva `playing` después de 10 segundos
            }, 10000);
          }
        };
      case 2:
        return () => {
          if (playing) return;
          if (e.escena) setEscena(e.escena, proyecto);
          else {
            setPopUpTitulo(e.nombre);
            setPopUpContenido("No hay una escena asociada a este link");
            setOpen(true);
          }
        };
      case 3:
        return () => {
          //capturemos la metrica
          setInteractions((prevInteractions) => ({
            ...prevInteractions,
            image_button: prevInteractions.image_button + 1,
          }));
          if (playing) return;
          let origin_src = "";
          if (modo) {
            origin_src = e.imagen;
          } else {
            origin_src =
              process.env.REACT_APP_BASE_URL +
              "/tour360/uploads/files/" +
              proyecto.userId +
              "/" +
              proyecto.id +
              "/" +
              e.imagen;
          }
          console.log("%c URL DE IMAGEN EN PLAYER", "color: teal", origin_src);
          if (e.imagen) {
            setPopUpTitulo(e.nombre);
            if (!isVR) {
              // setPopUpContenido(<img width="100%" src={e.imagen} />);
              setPopUpContenido(
                <img width="100%" src={origin_src} alt="imagen" />
              );
              setOpen(true);
            } else {
              setVRPopUpImage(e.imagen);
              //document.getElementById("imageURL").src = e.imagen;
              document.getElementById("imageURL").src = origin_src;
              setVRPopUpPosition(e);
              setPlaying(true);
            }
          }
        };
      default:
        return null;
    }
  };

  const calcularDistancia = (a, b) => {
    const dx = a.clientX - b.clientX;
    const dy = a.clientY - b.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  useEffect(() => {
    const registerEventPageVisit = async (data) => {
      if (!pageVisited && data && !modo) {
        await apiService.trackEvent({
          type: ANALYTICS_EVENT_TYPE.PAGE_VISIT,
          experienceData: data,
          additional: {},
        });
        setPageVisited(true);
      }
    };

    const getCountVisits = async (data) => {
      try {
        const res = await apiService.getVisitsByExperience(data.id);

        // controlar el error de caso 0
        const totalVisits = Number(res.totalRecords) || 0;
        console.log("CANTIDAD DE VISITAS: ", totalVisits);
        setCountVisits(totalVisits);
      } catch (error) {
        console.log(error);
      }
    };

    if (proyecto) {
      registerEventPageVisit(proyecto);
      setTituloHtml(proyecto.nombre);
    }

    //manejo de tour automatico para los componentes react
    if (proyecto) {
      const banderaFirstTime = localStorage.getItem("firstTime");
      //manejo el valor de la bandera para futuras implementaciones de un botón habilitador o deshabilitador del tour
      if (banderaFirstTime === null || banderaFirstTime === "false") { 
        setDesplegarMenu(true);
        driverObj.drive();
        localStorage.setItem("firstTime", "true");
      }
    }

    if (proyecto && !modo) {
      getCountVisits(proyecto);
    }
  }, [proyecto, pageVisited, modo]);

  const setFileData = (data) => {
    setProyecto({ ...data });
    let escenaPrincipal = data.escenas.find((e) => e.principal);
    console.log("<<<<<<<<<<<<<<<<LA ESCENA PRINCIPAL ES ", escenaPrincipal);
    if (escenaPrincipal) setEscena(escenaPrincipal.titulo, data);
    else setEscena(0, data);
  };

  const getProject = async () => {
    if (!location.proyectId) return;
    setCargando(true);

    //voy a buscar los datos de la experiencia en Api Backend
    try {
      //const dataStorage = JSON.parse( localStorage.getItem("authState"));
      const resFromApi = await apiService.getExperienceById(
        // dataStorage.griftin_token,
        location.proyectId
      );

      if (resFromApi) {
        setModo(false);
        const data = resFromApi;
        console.log("<<<<<<<<<DATOS DEL PROYECTO OBTENIDOS DEL API<<<<<<<<<<");
        console.log(data);
        const dataForDisplay = {
          escenas: data.scenes.map((e) => {
            return {
              fondo: e.url,
              inicio: e.initial,
              principal: e.principal,
              titulo: e.title,
              elementos: e.elements,
            };
          }),
          id: data.id,
          userId: data.userId,
          isAnimated: data.isAnimated,
          linkUrl: data.metadata?.urlLink || "",
          nombre: data.name,
          otras360: data.metadata?.otras360 || [],
          redesSociales: {},
          titulo360: data.metadata?.titulo360 || "",
        };
        console.log("DATA DE DATAFORDISPLAY: ", dataForDisplay);
        setFileData(dataForDisplay);
        //registerEventPageVisit(dataForDisplay);
        setAnimateEnabled(data.isAnimated);
        setUrlLink(dataForDisplay.linkUrl);
        //console.log("otras360 antes del set: ", dataForDisplay.otras360);
        setTitulo360Value(dataForDisplay.titulo360);
        setRedesState(data.redesSociales);
        setLinksData(dataForDisplay.otras360);
      } else {
        throw new Error("Esta Experiencia no existe en API");
      }
    } catch (error) {
      try {
        var a = await fetch(
          "https://codegstudio.com/apps/360/convertir.php?id=" +
            location.proyectId
        );
        console.log(
          "<<<<<<<<<<<<<<<<<<<<<<<<<RESPUESTA AL FETCH DE CODEGSTUDIO: ",
          a
        );
        if (!a.ok) {
          throw new Error(`Error en la respuesta del archivo: ${a.status}`);
        }
        a.json().then((data) => {
          setFileData(data);
          console.log(
            "<<<<<<<<<DATOS DEL PROYECTO OBTENIDOS DEL FILE<<<<<<<<<<"
          );
          console.log(data);
          //   setCargando(false);
          setAnimateEnabled(data.isAnimated);
          setUrlLink(data.linkUrl);
          setTitulo360Value(data.titulo360);
          setRedesState(data.redesSociales);
          setLinksData(data.otras360);
        });
      } catch (error) {}
    } finally {
      setCargando(false);
    }
  };

  const isMobile = () => {
    document.querySelector("a-scene").addEventListener("enter-vr", function () {
      setIsVR(true);
    });

    document.querySelector("a-scene").addEventListener("exit-vr", function () {
      setIsVR(false);
    });

    if (!isMobileXp) {
      cursor.current.setAttribute(
        "cursor",
        "rayOrigin: mouse; fuse: true; fuseTimeout: 100"
      );
      cursor.current.setAttribute("visible", false);
    } else {
      cursor.current.setAttribute("cursor", "fuse: true; fuseTimeout: 100");
      cursor.current.setAttribute(
        "geometry",
        "primitive: ring; radiusInner: 0.02; radiusOuter: 0.03"
      );
    }
    camara.current.setAttribute("wasd-controls", { enabled: false });
  };

  const setMouseEvents = () => {
    let rotationSpeed = 0.01;
    let tt = document.getElementById("tooltip");
    window.addEventListener("mousemove", (event) => {
      tt.style.left = event.pageX - 50 + "px";
      tt.style.top = event.pageY - 50 + "px";
    });

    window.addEventListener("wheel", (event) => {
      let delta = event.deltaY || event.detail || event.wheelDelta;
      zoomIn(delta < 0);
    });
    window.addEventListener("touchstart", (event) => {
      distanciaTouch = 0;
      let toques = Array.from(event.touches);
      if (toques.length > 1) {
        distanciaTouch = calcularDistancia(toques[0], toques[1]);
        console.log("toques iniciales", distanciaTouch);
        console.log(
          "toques iniciales",
          calcularDistancia(toques[0], toques[1])
        );
      }
      if (toques.length == 1) {
        touchPos = { x: toques[0].clientX, y: toques[0].clientY };
        console.log("starttouch", {
          x: toques[0].clientX,
          y: toques[0].clientY,
        });
      }
    });

    window.addEventListener("touchend", (event) => {
      let toques = Array.from(event.changedTouches);
      console.log("endtouch", event);
      if (toques.length == 1) {
        if (toques[0].clientX == touchPos.x && toques[0].clientY == touchPos.y)
          tapBackground();
        setDesplegarMenu((prev) => !prev);
      }
    });

    window.addEventListener("touchmove", (event) => {
      let toques = Array.from(event.touches);
      if (toques.length > 1) {
        let esAcercamiento =
          distanciaTouch < calcularDistancia(toques[0], toques[1]);
        zoomIn(esAcercamiento);
        console.log("distancia", distanciaTouch);
      }
    });

    window.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") {
        camara.current.components["look-controls"].yawObject.rotation.y +=
          rotationSpeed;
      } else if (e.key === "ArrowRight") {
        camara.current.components["look-controls"].yawObject.rotation.y -=
          rotationSpeed;
      } else if (e.key === "ArrowUp") {
        camara.current.components["look-controls"].pitchObject.rotation.x +=
          rotationSpeed;
      } else if (e.key === "ArrowDown") {
        camara.current.components["look-controls"].pitchObject.rotation.x -=
          rotationSpeed;
      }
    });

    window.addEventListener("click", (e) => {
      setAnimateEnabled(false);
      //setDesplegarMenu(prev => !prev);
      // console.log("showMenuFloat antes de cambiarlo", showMenuFloat);
      // setShowMenuFloat((prev) => {
      //   console.log("showMenuFloat adentro de setShowMenu es: ", prev);
      //   return !prev;
      // });
    });

    /*window.addEventListener('keyup', (e) => {
            if (e.key === 'ArrowLeft') {
              this.rotateLeft = false;
            } else if (e.key === 'ArrowRight') {
              this.rotateRight = false;
            }
          });*/
  };

  const cerrarPopUp = () => {
    setOpen(false);
    if (iframe.current) iframe.current.src = "";
    setPopUpContenido(null);
    setPlaying(false);
    setMobileVideoSrc("");
    document.getElementById("imageURL").src = "";
    setVRPopUpImage(null);
  };

  const tapBackground = () => {
    setDesplegarMenu((prev) => !prev);
    let escenasContainer = document.getElementById("escenasContainer");
    escenasContainer.classList.add("hidden-scenes");
    console.log("tapBackground", timeOutClick);

    // if (timeOutClick) return;
    // let banner = document.getElementById("banner");
    // let escenasContainer = document.getElementById("escenasContainer");
    // banner.classList.remove("hidden-menu");
    // let to = setTimeout(() => {
    //   console.log("intervalo");
    //   let banner = document.getElementById("banner");
    //   if (
    //     banner &&
    //     banner.classList &&
    //     !banner.classList.contains("hidden-menu")
    //   ) {
    //     banner.classList.add("hidden-menu");
    //     escenasContainer.classList.add("hidden-scenes");
    //   }
    //   setTimeOutClick(null);
    // }, 1000);
    // setTimeOutClick(to);
  };

  const viewScenasShortcut = () => {
    let escenasContainer = document.getElementById("escenasContainer");
    escenasContainer.classList.remove("hidden-scenes");
  };

  const showTooltip = (e) => {
    let tt = document.getElementById("tooltip");
    tt.classList.remove("hidden");
    tt.innerHTML = e.nombre;
    //tt.style.left =
    /*
        tt.setAttribute('visible', true);
        tt.setAttribute('text', 'value', e.nombre);
        //setVRPopUpPosition(e, 'tooltip')
        tt.setAttribute('position', `${e.position.x-2} ${e.position.y} ${-12}`);*/
  };

  const hideTooltip = (e) => {
    let tt = document.getElementById("tooltip");
    tt.classList.add("hidden"); /*
        tt.setAttribute('visible', false);*/
  };

  useEffect(() => {
    console.log("[PLAYER-VIEW]:::EFFECT", !location.proyectId);
    setMouseEvents();
    getProject();
    isMobile();
    ReactGA.event({
      category: "User",
      action: "onLoadExperience",
      data: {
        user_rol: "visitor",
      },
    });
    document.addEventListener("click", handleCardRelacionadas);
  }, []);

  //effect que maneja analiticas de tiempo de permanencia
  useEffect(() => {
    startTime = Date.now();

    const handleBeforeUnload = async (event) => {
      try {
        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000;
        setSpentTime(duration);
        //sessionStorage.setItem("Tiempo-Permanencia", duration);
        const objInteractions = interactions;

        const dataSpent = {
          id: proyecto.id,
          userId: proyecto.userId,
          additional: {
            timeSeconds: duration,
          },
        };

        const dataInteractions = {
          id: proyecto.id,
          userId: proyecto.userId,
          additional: {
            interactions: objInteractions,
          },
        };
        // await apiService.trackEvent({
        //        type: ANALYTICS_EVENT_TYPE.TIME_SPENT,
        //        experienceData: dataSpent,
        //      })

        await Promise.all([
          apiService.trackEvent({
            type: ANALYTICS_EVENT_TYPE.TIME_SPENT,
            experienceData: dataSpent,
          }),
          apiService.trackEvent({
            type: ANALYTICS_EVENT_TYPE.INTERACTIONS,
            experienceData: dataInteractions,
          }),
        ]);
      } catch (error) {
        console.error("Error al enviar datos:", error);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [proyecto, interactions]);

  useEffect(() => {
    console.log("ANIMACION HABILITADA?", animateEnabled);
  }, [animateEnabled]);

  //nuevo metodo que dispara enterVR
  const actionEnterVR = () => {
    const scene = document.querySelector("a-scene");
    if (scene && scene.enterVR) {
      scene.enterVR();
    }
  };

  //metodo que salida de modo VR
  const handleExitVR = () => {
    if (escena.current) {
      escena.current.exitVR();
      setFullscreen();
    }
    // const scene = document.querySelector("a-scene");
    // if (scene && scene.exitVR) {
    //   scene.exitVR();
    // }
  };

  const onConfirmExitVR = () => {
    setShowModalVR(false);
    handleExitVR();
  };

  const onCancelExitVR = () => {
    setShowModalVR(false);
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>{`GRIFTIN360 - ${tituloHtml}`}</title>
      </Helmet>
      <LoadingSpin visible={cargando} />
      <OpenProject visible={!location.proyectId} setFileData={setFileData} />
      <PopUpMsg
        visible={open}
        titulo={popUpTitulo}
        contenido={popUpContenido}
        alCerrar={cerrarPopUp}
      />
      <EscenasMenu
        escenas={proyecto ? proyecto.escenas : []}
        setEscena={(e) => setEscena(e, proyecto)}
      />
      <div id="tooltip" className="hidden"></div>
      {!cargando && proyecto && !open && !playing && (
        <div id="banner" className="hidden-menu">
          {/* <div
              className="controlbutton"
              id="zoominbutton"
              onClick={() => zoomIn(true)}
            ></div>
            <div
              className="controlbutton"
              id="zoomoutbutton"
              onClick={() => zoomIn(false)}
            ></div> */}
          {/* <div
              className="controlbutton"
              id="fullscreenbutton"
              onClick={setFullscreen}
            ></div> */}
          {/* <div
              className="controlbutton"
              id="linkbutton"
              onClick={handleClickLinkButton}
            ></div> */}
          {/* <div
              className="controlbutton"
              id="vrbutton"
              ref={vrbutton}
              onClick={setVR}
              style={{ display: isMobileXp ? "block" : "none" }}
            ></div> */}
          {/* <div
              className="controlbutton"
              id="shortcutbutton"
              ref={vrbutton}
              onClick={viewScenasShortcut}
            >
              <ApartmentOutlined style={{ fontSize: 45 }} />
            </div> */}
        </div>
      )}
      <a-scene
        ref={escena}
        onMouseDown={(e) => {
          if (e.clientX != undefined)
            setMousePos({ x: e.clientX, y: e.clientY });
        }}
        onMouseUp={(e) => {
          if (
            e.clientX != undefined &&
            e.clientX == mousePos.x &&
            e.clientY == mousePos.y
          )
            tapBackground();
        }}
      >
        <a-assets>
          <img id="plus" src={info} alt="plus" />
          <img id="gafas" src={gafas_white} alt="gafas" />
          <img id="playvideo" src={video} alt="playvideo" />
          <img id="image" src={image} alt="imageAlt" />
          <img id="imageURL" src="" alt="imageURL" crossorigin="anonymous" />

          <img id="exitIcon" src="exitPng" alt="" />
          <video
            ref={videoControl}
            id="videocontrol"
            width="100%"
            src={mobileVideoSrc}
            controls
            autoPlay
          />

          <img id="portalImg" src={portal} alt="portal img" />
          {proyecto && proyecto.escenas.length > 0 && (
            <img
              key="escena0"
              id="escena0"
              src={proyecto.escenas[0].fondo}
              alt="escena0"
            />
          )}
        </a-assets>
        <a-sky
          ref={cielo}
          id="cielo"
          src={
            proyecto && proyecto.escenas.length
              ? proyecto.escenas[escenaIndex].fondo
              : ""
          }
          rotation="0 90 0"
          material="opacity: 1; transparent:true"
          animation__fade_in="property: material.opacity; from: 0; to: 1; dur: 1500; startEvents: fadein"
          animation__fade_out="property: material.opacity; from: 1; to: 0; dur: 500; startEvents: fadeout"
        />
        <a-camera
          id="camara"
          ref={camara}
          animation={`property: rotation; from: 0 0 0; to: 0 360 0; dur: 800000; loop: true; easing:linear; enabled: ${animateEnabled};`}
        >
          <a-cursor
            position="0 0 -1"
            id="cursor"
            ref={cursor}
            cursor="fuse: true; fuseTimeout: 100"
            raycaster="objects: .objeto"
            material="shader:flat; color:white"
          />
          {isVRMode && (
            <a-entity
              cursor="fuse: true; fuseTimeout: 1000"
              rayOrigin="controller"
              raycaster="objects: .interactable; showLine: true; debug: true"
              position="0 0 -1"
              geometry="primitive: ring; radiusInner: 0.02; radiusOuter: 0.03"
              material="color: #E94E1B; shader: flat"
              visible={isVRMode}
            ></a-entity>
          )}
        </a-camera>
        <a-mixin
          id="msg-button"
          geometry="primitive: circle; radius:2;"
          animation__scale="property: scale; to: 2.2 2.2 2.2; dur: 200; startEvents: mouseenter"
          animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
          material="color: #E94E1B; opacity: 1; transparent: true; shader: flat; src: #plus; side: double"
          //text="font: #fontRoboto"
          clickeable
        ></a-mixin>
        <a-mixin
          id="view-button"
          geometry="primitive: circle; radius:5;"
          material="color: #E94E1B; opacity: 1; transparent:true; shader: flat; src: #gafas; side: double"
          animation__scale="property: scale; to: 2.2 2.2 2.2; dur: 200; startEvents: mouseenter"
          animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
          animation="property: rotation; dur:3000; to: 0 360 0; easing:linear; loop:true"
          clickeable
        ></a-mixin>
        <a-mixin
          id="video-button"
          geometry="primitive: circle; radius:1;"
          material="color: white; opacity: 0.5; transparent: true; shader: flat; src: #playvideo; side: double"
          animation__scale="property: scale; to: 2.2 2.2 2.2; dur: 200; startEvents: mouseenter"
          animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
          border="sides: 4; radius: 25; wall: #rail;"
          clickeable
        ></a-mixin>
        <a-mixin
          id="image-button"
          geometry="primitive: circle; radius:2;"
          material="color: #E94E1B; opacity: 1; transparent: true; shader: flat; src: #image; side: double"
          animation__scale="property: scale; to: 2.2 2.2 2.2; dur: 200; startEvents: mouseenter"
          animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
          border="sides: 4; radius: 25; wall: #rail;"
          clickeable
        ></a-mixin>

        <a-mixin
          id="portal"
          geometry="primitive: circle; segments: 32"
          material=" transparent: true; opacity: 0.2"
          //raycastable
          animation__scale="property: scale; to: 1.2 1.2 1.2; dur: 200; startEvents: mouseenter"
          animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
          // animation__mouseenterscale="property: scale; from: 1 1 1; to: 1.1 1.1 1.1; easing: easeInOutQuad; dir: alternate; loop: true; startEvents: mouseenter; dur: 1000; pauseEvents: mouseleave"
          scale="0.6 0.6 0.6"
        ></a-mixin>

        <a-mixin
          id="hoverAnimation"
          animation="property: object3D.position.y; from: -1.8; to: -1; dir: alternate; loop: true; dur: 5000; easing: easeInOutCubic; pauseEvents: touched"
        ></a-mixin>

        <a-plane
          id="player"
          width="42"
          height="32"
          look-at="#camara"
          position={mobilePlayerPos}
          visible={playing}
          material="color: black; transparent: true; opacity: 0.6"
          //material="color: white"
        >
          {/* <a-entity
            position="1.5 8.5 10"
            text={
              "shader: msdf; anchor: center; width: 30; font: /fonts/custom-font-msdf/customFontAframe-msdf.json; font-image: /fonts/custom-font-msdf/customFontAframe.png; color: black; value: " +
              popUpTitulo
            }
          /> */}
          <a-text
            position="1.5 8.5 10"
            shader="msdf"
            anchor="center"
            width="30"
            font="/fonts/custom-font-msdf/customFontAframe-msdf.json"
            font-image="/fonts/custom-font-msdf/customFontAframe.png"
            negate="false"
            color="white"
            baseline="top"
            opacity="1"
            transparent="false"
            value={popUpTitulo}
          />

          {!popUpContenido && !VRPopUpImage && (
            <a-video
              src="#videocontrol"
              width="37"
              height="23"
              rotation="0 0 0"
              position="0 0 1"
              visible={!popUpContenido && !VRPopUpImage}
              border="sides: 4; radius: 25; wall: #rail;"
            ></a-video>
          )}
          {VRPopUpImage && (
            <Entity
              width="37"
              height="23"
              rotation="0 0 0"
              position="0 0 1"
              visible={VRPopUpImage}
              geometry="primitive: plane; height: 23; width: 37"
              material="shader: flat; src: #imageURL; side: double"
              border="sides: 4; radius: 25; wall: #rail;"
            ></Entity>
          )}
          {popUpContenido && (
            <Entity
              position="-12.5 2.5 10"
              visible={popUpContenido}
              text={
                popUpContenido
                  ? "shader: msdf; negate: false; baseline: top; anchor: left; width: 25; font: /fonts/custom-font-msdf/customFontAframe-msdf.json; font-image: /fonts/custom-font-msdf/customFontAframe.png; color: white; value: " +
                    popUpContenido
                  : ""
              }
            />
          )}
          <Entity
            position="8 -10 10"
            className="objeto interactable"
            animation__scale="property: scale; to: 1.5 1.5 1.5; dur: 200; startEvents: mouseenter"
            animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
            text="shader: msdf; anchor: left; width: 30; font: /fonts/custom-font-msdf/customFontAframe-msdf.json; negate: false; font-image: /fonts/custom-font-msdf/customFontAframe.png; color: white; value: ok"
            events={{
              click: cerrarPopUp,
            }}
          />
        </a-plane>
        {/*<Entity
              id="tooltip"
              visible={false}
              text="shader: msdf; anchor: left; width: 40; font: https://cdn.aframe.io/examples/ui/Viga-Regular.json; color: white; value: ok"
                  />*/}

        {proyecto && proyecto.escenas.length
          ? proyecto.escenas[escenaIndex].elementos.map((e, k) => {
              let position = proyecto.escenas.findIndex(
                (es) => es.titulo === e.escena
              );
              //evitamos error de no existencia de escena
              if (position < 0) position = 0;

              return (
                <a-entity
                  key={k}
                  position={`${e.position.x} ${e.position.y} ${e.position.z}`}
                  rotation={`${e.rotation.x} ${e.rotation.y} ${e.rotation.z}`}
                >
                  <Entity
                    //key={k}
                    position={`${e.position.x} ${e.position.y} ${e.position.z}`}
                    rotation={`${e.rotation.x} ${e.rotation.y} ${e.rotation.z}`}
                    mixin={getTipo(e)}
                    {...(e.tipo === 2
                      ? { [`to-scene-${position}`]: true }
                      : {})}
                    id={e.id}
                    className="objeto interactable"
                    visible={
                      getTipo(e) !== "video-button"
                        ? !playing
                        : !isVRMode && !playing
                    }
                    events={{
                      click: getFunction(e),
                      mouseenter: (s) => showTooltip(e),
                      mouseleave: () => hideTooltip(e),
                    }}
                  >
                    {isVRMode && e.tipo === 2 && (
                      <a-entity position="0 6 0">
                        <a-text
                          value={e.nombre || ""}
                          scale="9 9 9"
                          align="center"
                          color="#E94E1B"
                          visible={true}
                          shader="msdf"
                          font="/fonts/custom-font-msdf/customFontAframe-msdf.json"
                          font-image="/fonts/custom-font-msdf/customFontAframe.png"
                          negate="false"
                          baseline="top"
                          //id={`text-${e.id}`}
                        />
                        <a-text
                          value={e.nombre || ""}
                          rotation="0 180 0"
                          scale="9 9 9"
                          align="center"
                          color="#E94E1B"
                          visible={true}
                          shader="msdf"
                          font="/fonts/custom-font-msdf/customFontAframe-msdf.json"
                          font-image="/fonts/custom-font-msdf/customFontAframe.png"
                          negate="false"
                          baseline="top"
                          //id={`text-${e.id}`}
                        />
                        {/* <a-text
                        value={e.nombre || ""}                        
                        scale="9 9 9"
                        align="center"
                        color="#FFF"
                        visible={true}
                        //id={`text-${e.id}`}
                      />
                      <a-text
                        value={e.nombre || ""}                        
                        rotation="0 180 0"
                        scale="9 9 9"
                        align="center"
                        color="#FFF"
                        visible={true}
                        //id={`text-${e.id}`}
                      /> */}
                      </a-entity>
                    )}
                  </Entity>
                </a-entity>
              );
            })
          : ""}

        {proyecto &&
          proyecto.otras360 &&
          proyecto.otras360.length > 0 &&
          isVRMode &&
          proyecto.otras360.map((element, index) => {
            const newPosicionX = positionX + index * 2;

            return (
              <Otra360VR
                key={index}
                dataOtra={element}
                Xpos={newPosicionX}
                fondo="#escena0"
                modo={modo}
                onClick={() => {
                  //capturemos la metrica
                  setInteractions((prevInteractions) => ({
                    ...prevInteractions,
                    experience_navigation_button:
                      prevInteractions.experience_navigation_button + 1,
                  }));
                }}
              />
            );
          })}

        {/* boton de exit */}

        {/* {
          isVRMode && (
            <a-entity position="0 -8 15">        
          <a-image
            //  position="0 -8 15"
             class="interactable"
            src={exitPng}
            animation="property: rotation; dur:3000; to: 0 360 0; easing:linear; loop:true"                      
            scale="2 2 2"
            color="#E94E1B"
            onClick={() => {              
              setShowModalVR(true)
            }}
            
          ></a-image>                
        </a-entity>
          )
        } */}
        {/* modalVR de confirmacion de Exit */}

        {/* {
          isVRMode && showModalVR && (
            <ModalConfirmationVR isVisible={showModalVR} onConfirm={onConfirmExitVR} onCancel={onCancelExitVR} position="0 1 3" message="Salir de Modo VR?"/>
          )
        } */}

        <a-entity
          //position="-20 0 -1"
          position="20 0 -1"
          class="interactable objeto"
          //tour-automatico={proyecto}
          scale="2 2 2"
          animation="property: rotation; dur: 3000; to: 0 360 0; easing: linear; loop: true"
          geometry="primitive: sphere; segmentsWidth: 32; segmentsHeight: 16; radius: 0.8"
          material="color: #B0B0B0; metalness: 0.8; roughness: 0.2"
          onClick= {() => navigationAction(proyecto, escenaIndex, setEscenaIndex)}
         
        ></a-entity> 
      </a-scene>
      <button
        onClick={actionEnterVR}
        className="third-step"
        style={{
          position: "absolute",
          top: "50%",
          left: 10,
          padding: 20,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          color: "white",
          border: "solid 1px white",
          fontWeight: "bold",
        }}
      >
        VR
      </button>
      {showAlertLink && (
        <Alert
          id="alertLink"
          message={alertErrorMessage.titulo}
          description={alertErrorMessage.mensaje}
          type="error"
          style={{ opacity: 0.4 }}
        />
      )}
      <h1 class="titleExperiencia first-step">{titulo360Value}</h1>
      {desplegarMenu && (
        <div
          className={`animate__animated ${
            showMenuFloat ? "animate__fadeIn" : "animate__fadeOut"
          }`}
        >
          <MenuComponent
            id="menuFlotante"
            handleLinkButton={handleClickLinkButton}
            handleOptionButton={handleClickOptionButton}
            handleClickPlusButton={handleClickPlusButton}
            handleClickMinusButton={handleClickMinusButton}
            handleClickFullScreenButton={handleClickFullScreenButton}
            handleClickScenesButton={handleClickScenesButton}
            desplegarMenu={desplegarMenu}
            setDesplegarMenu={setDesplegarMenu}
            isMobile={isMobileXp}
            // setVR = {setVR}
            setVR={() => {}}
          />
        </div>
      )}
      <MenuOtras
        // id="menuFlotanteOtras"
        handleLinkButton={handleClickLinkButton}
        // handleOptionButton={handleClickOptionButton}
      />
      <Visitas cantidad={countVisits} />
      <MenuRedes handleLinkButton={handleShareButton} />
      <div
        ref={menuEnlacesOtrasRef}
        onClick={() => {
          //capturemos la metrica
          setInteractions((prevInteractions) => ({
            ...prevInteractions,
            experience_navigation_button:
              prevInteractions.experience_navigation_button + 1,
          }));
        }}
      >
        <MenuEnlaces
          handleLinkButton={handleNavigateRelated}
          data={linksDataState}
          handleShowFromPadre={handleCardRelacionadas}
          desplegarCard={desplegarCard}
        />
      </div>
      {/* <div style={{display: "block"}}>
          <CardCar />
        </div> */}
      <MenuEscenas
        handleClickScenesButton={handleClickScenesButton}
      ></MenuEscenas>
      <ModalShare
        showModal={showModalShare}
        setShowModal={setShowModalShare}
        titulo={proyecto?.nombre}
        url={window.location.href}
      />
    </>
  );
}

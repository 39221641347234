




const API_URL = process.env.REACT_APP_BASE_URL;



const apiService = {
//metodo de testing de conexion
 getTest: async () => {
    
 
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }
    const data = await response.json();    
    return data;
  } catch (error) {
    console.error('Error en la petición GET:', error);
    throw error;
  }
 },

 postNewExperience: async (data, token) => { 
  try {
    const response = await fetch(`${API_URL}/tour360/experience`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`Error HTTP: ${response.status}`);
    }
    const dataResponse = await response.json();
    console.log(dataResponse);
    return dataResponse;
  } catch (error) {
    console.error('Error en la petición POST:', error);
    throw error;
  }
 },

 getExperiencesByUser: async (token) => {
  
   try {
     const response = await fetch(`${API_URL}/tour360/experience`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`
       }       
     });
     if (!response.ok) {
       throw new Error(`Error HTTP: ${response.status}`);
     }
     const dataResponse = await response.json();
     console.log(dataResponse);
     return dataResponse;
   } catch (error) {
     console.error('Error en la petición POST:', error);
     throw error;
   }
  },
  getExperienceById: async (id) => {    
    
    try {
      const response = await fetch(`${API_URL}/tour360/experience/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${token}`
        }       
      });      
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const dataResponse = await response.json();      
      return dataResponse;
    } catch (error) {
      console.error('Error en la petición GET EXPERIENCIA BY ID:', error);
      throw error;
    }
   },
   postNewScene: async (data) => {    
    try {
      const formData = new FormData();
      formData.append('experienceId', data.experienceId);
      formData.append('title', data.title);
      formData.append('name', data.description);
      formData.append('principal', data.principal);

      const dataOrigen = {
        x: 0,
        y: 0
      }

      formData.append('initial', JSON.stringify(dataOrigen));
      formData.append('elements', JSON.stringify(data.elements));
      // const elementsData = [{
      //   id: '17200190946045',
      //   tipo: 1,
      //   texto: 'Iglesia Estilo Colonial',
      //   nombre: 'datos turísticos',
      //   titulo: 'Datos Históricos',
      //   position: { x: -0.5, y: -1.46, z: -22 },
      //   rotation: { x: 0, y: 0, z: 0 }
      // }];
      
      //formData.append('elements', JSON.stringify(elementsData));
      
      
      formData.append('image360', data.image360);      

      
      // formData.forEach((value, key) => {
      //   console.log(key, value);
      // });
      
      const response = await fetch(`${API_URL}/tour360/scene`, {
        method: 'POST',        
        headers: {               
          'Authorization': `Bearer ${data.token}`
        },
        body: formData
      });
      // console.log("Respuesta al Post de Imagen")
      // console.log(response)
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const dataResponse = await response.json();
      console.log(dataResponse);
      return dataResponse;
    } catch (error) {
      console.error('Error en la petición POST:', error);
      throw error;
    }
   },
   deleteSceneById: async (token, id) => {
    
    try {
      const response = await fetch(`${API_URL}/tour360/scene/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }       
      });
      
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const dataResponse = await response.json();
      console.log(dataResponse);
      return dataResponse;
    } catch (error) {
      console.error('Error en la petición DELETE:', error);
      throw error;
    }
   },
   updateExperienceById: async (token, id, data) => {
    
    
     try {
      const response = await fetch(`${API_URL}/tour360/experience/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
      console.log("Respuesta al PUT DE EXPERIENCIA")
      console.log(response)
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const dataResponse = await response.json();
      console.log(dataResponse);
      return dataResponse;
      
     } catch (error) {
       console.error('Error en la petición PUT:', error);
       throw error;
     }
   },

   updateSceneById: async (token, id, data) => {   
      const formData = new FormData();
      //configuracion y evento que sirve para agregar a elementos un nuevo tipo -1 que se usará para manejar configuración de la lista de elementos
      //empezando por el jump a otra escena
      // if(data.elements){
      //   const hasConfigElement = data.elements.some(element => element.tipo === -1);
      //   if (!hasConfigElement) {
      //     data.elements.push({
      //       id: Date.now().toString(),
      //       tipo: -1,
      //       texto: '',
      //       nombre: 'configuracion',
      //       titulo: 'Configuración',
      //       position: { x: 0, y: 0, z: 0 },
      //       rotation: { x: 0, y: 0, z: 0 }
      //     });
      //   }
      // }

      if (data.title) {
        formData.append('title', data.title);
      }
    
      if (data.name) {
        formData.append('name', data.name);
      }
    
      if ('principal' in data) {
        formData.append('principal', data.principal);
      }
      if (data.initial) {
        formData.append('initial', JSON.stringify(data.initial));
      }
    
      if (data.elements) {        
        formData.append('elements', JSON.stringify(data.elements));
      }
    
      if (data.image360) {
        formData.append('image360', data.image360);
      }

     try {
      const response = await fetch(`${API_URL}/tour360/scene/${id}`, {  
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,      
        },
        body: formData
    });            
    
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const dataResponse = await response.json();            
      return dataResponse;
      
     } catch (error) {
       console.error('Error en la petición PUT:', error);
       throw error;
     }
   },

   deleteExperienceById: async (token, id) => {       
    try {
     const response = await fetch(`${API_URL}/tour360/experience/${id}`, {
       method: 'DELETE',
       headers: {
           'Authorization': `Bearer ${token}`           
       }       
   });  
  
     if (!response.ok) {
       throw new Error(`Error HTTP: ${response.status}`);
     }
     const dataResponse = await response.json();      
     return dataResponse;
     
    } catch (error) {
      console.error('Error en la petición DELETE experience:', error);
      throw error;
    }
  },

  trackEvent: async ( eventData) => {           
    const dataBody = {
      name: eventData.type,
      origin: eventData.experienceData.id,
      userId: eventData.experienceData.userId,
      additional: eventData.experienceData.additional || {}
    }
    
    try {
     const response = await fetch(`${API_URL}/analytics`, {
       method: 'POST',
       headers: {
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(dataBody),
   });  
    
     if (!response.ok) {
       throw new Error(`Error HTTP: ${response.status}`);
     }
     const dataResponse = await response.json();      
     return dataResponse;
     
    } catch (error) {
      console.error('Error en la petición POST analytic experience:', error);
      throw error;
    }
  },

  getVisitsByExperience: async ( idExperience) => {       
    
    const dataBody = {
     "filters": {
	 	 "name": "PAGE_VISIT",
		 "origin": idExperience
	}
    }
    
    try {
     const response = await fetch(`${API_URL}/analytics/list`, {
       method: 'POST',       
       headers: {
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(dataBody),
   });  

     if (!response.ok) {
       throw new Error(`Error HTTP: ${response.status}`);
     }
     const dataResponse = await response.json();      
     return dataResponse;
     
    } catch (error) {
      console.error('Error en la petición GET de PAGE_VISIT  experience:', error);
      throw error;
    }
  },
  postNewFile: async (data) => {    
    console.log("%c DATA RECIBIDA EN EL SERVICIO", "color: orange", data)
    try {
      if (data.file && data.file.name) {
        const newFileName = data.sceneId + "-"+ data.file.name.replace(/\s/g, '_');
        data.file = new File([data.file], newFileName, { type: data.file.type });
      }
      const formData = new FormData();
      formData.append('experienceId', data.experienceId);

      formData.append('file', data.file);
      
      
      
      const response = await fetch(`${API_URL}/tour360/uploads`, {
        method: 'POST',        
        headers: {               
          'Authorization': `Bearer ${data.token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }      
      
      return {
        success: true,
        file_name: data.file.name,
      };
    } catch (error) {
      console.error('Error en la petición POST:', error);
      throw error;
    }
   },
   deleteFileByName: async (data) => {    
    console.log("%c DATA RECIBIDA EN EL SERVICIO", "color: blue", data)
    try {
      let userId, fileName, experienceId;
      if (data.userId && data.fileName && data.experienceId && data.token) {
        ({userId, fileName, experienceId} = data);
      }else{
        throw new Error('Error en la petición DELETE: No se enviaron los datos necesarios');
      } 
      
      
      const response = await fetch(`${API_URL}/tour360/uploads/files/${userId}/${experienceId}/${fileName}`, {
        method: 'DELETE',        
        headers: {               
          'Authorization': `Bearer ${data.token}`
        },        
      });

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }      
      
      return {
        success: true,
        message: `El archivo ${fileName} fue eliminado correctamente`,
      };
    } catch (error) {
      console.error('Error en la petición DELETE:', error);
      throw error;
    }
   },











 

  // Método para obtener datos
  getData: async (endpoint) => {
    try {
      //const response = await fetch(`${API_URL}/${endpoint}`);
      const response = await fetch('/mockData.json');
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
    
      return data;
    } catch (error) {
      console.error('Error en la petición GET:', error);
      throw error;
    }
  },

  // getExperienceById: async (id) => {
  //   try {
  //     const response = await fetch('/mockData.json');
  //     if (!response.ok) {
  //       throw new Error(`Error HTTP: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     const experience = data.find(x => x.id === id);      
  //     return experience;      
  //   } catch (error) {
  //     console.error('Error en la petición GET:', error);
  //     throw error;
  //   }
  // },

  // Método para enviar datos (POST)
  postData: async ( payload={message:"Hello World!"}) => {
    try {
      const response = await fetch('/mockData.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error en la petición POST:', error);
      throw error;
    }
  },

  //metodo para enviar imagen a Cloudinary
  postImage: async (imageBynarized) => {
    const preset_name = "q93jy2fr";
 const cloud_name = "xxavierargentino";

    try {
      const formData = new FormData();
      formData.append('file', imageBynarized);
      formData.append('upload_preset', preset_name);
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        {
          method: 'POST',
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      
    }
  },

  // Método para actualizar datos (PUT)
  putData: async (endpoint, payload) => {
    try {
      const response = await fetch(`${API_URL}/${endpoint}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error en la petición PUT:', error);
      throw error;
    }
  },

  // Método para eliminar datos (DELETE)
  deleteData: async (endpoint) => {
    try {
      const response = await fetch(`${API_URL}/${endpoint}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      return response.status === 204;
    } catch (error) {
      console.error('Error en la petición DELETE:', error);
      throw error;
    }
  }
};

export default apiService;

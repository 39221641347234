import React, { useEffect, useState } from "react";
import { Card, Slider, Input, Select, Button, Popconfirm, Upload, Switch } from "antd";
import apiService from "../services/apiServices";
import { AuthContext } from "../context/auth-context/AuthContext";

export default function ElementInspector({ proyecto, escenaParaApi, eliminarElemento, elemento, guardarCambios, guardarCambiosApi, modo, setLoading, setShowAlert, setAlertData, actualizarExperienciaAPI}){

    const [posEditing, setPosEditing] = useState(elemento.position);
    const [rotEditing, setRotEditing] = useState(elemento.rotation);
    const [titulo, setTitulo] = useState(elemento.titulo);
    const [texto, setTexto] = useState(elemento.texto);
    const [escena, setEscena] = useState(elemento.escena);
    const [video, setVideo] = useState(elemento.video);
    const [imagen, setImagen] = useState(elemento.imagen);
    const [urlImgElement, setUrlImgElement] = useState(`${process.env.REACT_APP_BASE_URL}/tour360/uploads/files/${proyecto.userId}/${proyecto.id}/${elemento.imagen}`)
    
    //state que habilita boton de guardar API
    const [allowUpdateButton, setAllowUpdateButton] = useState(false)

    //metodos del context que maneja estado del usuario logeado
    const { handleRefreshToken } = React.useContext(AuthContext);

    

    useEffect(() => {      
      setImagen(elemento.imagen);
      setUrlImgElement(`${process.env.REACT_APP_BASE_URL}/tour360/uploads/files/${proyecto.userId}/${proyecto.id}/${elemento.imagen}`);
    }, [proyecto, elemento, imagen, urlImgElement])
    

    const handleButtonGuardar = () => {
        guardarCambiosApi(elemento);
    }


    const alCambiarTitulo = (e) => {
        setTitulo(e.target.value);
        elemento.titulo = e.target.value;
        setAllowUpdateButton(true);
    }

    const alCambiarTexto = (e) => {
        setTexto(e.target.value);
        elemento.texto = e.target.value;
        setAllowUpdateButton(true);
    }

    const alCambiarEscena = (e) => {
        setEscena(e);
        elemento.escena = e;
        guardarCambios(elemento);
        setAllowUpdateButton(true);
    }

    const alCambiarVideo = (e) => {
        setVideo(e.target.value);
        elemento.video = e.target.value;
        setAllowUpdateButton(true);
    }

    const alCambiarImagen = async(e) =>{
      if(modo){
        setImagen(e);
        elemento.imagen = e;
        setAllowUpdateButton(true);
      }else{        
        try {          
          const tokenVerified = await handleRefreshToken();
          const bodyDataForPut = {
            elements: escenaParaApi.elementos.map((el) => {
                if(el.id === elemento.id){
                    return {
                        ...el,
                        imagen: e
                    }
                  }else{
                    return el;
                  }
            })
          };    
            
          const res = await  apiService.updateSceneById(
              tokenVerified,
              escenaParaApi.id,
              bodyDataForPut
            );
            //setUrlImgElement(`${process.env.REACT_APP_BASE_URL}/tour360/uploads/files/${proyecto.userId}/${proyecto.id}/${e}`);
            if(res.ok){
              setAlertData({
                type: "success",
                message: "Exito",
                description: "Se actualizo correctamente la escena principal",
              });
              setShowAlert(true);
            }
          
          
          } catch (error) {
            console.warn(error)
            setAlertData({
              type: "error",
              message: "Error",
              description: "Error al actualizar la imagen en la escena",
            })
            setShowAlert(true);
          }finally{
            //setLoading(false);
            actualizarExperienciaAPI();
      }
    }
    }

    const alCambiarPos = (e) => {
        if (elemento){
            document.getElementById(elemento.id).setAttribute('position', `${e.x} ${e.y} ${e.z}`);
            elemento.position = e;            
            setPosEditing(e);
            setAllowUpdateButton(true);            
        }        
    }

    const alCambiarRot = (e) => {
        if (elemento){
            document.getElementById(elemento.id).setAttribute('rotation', `${e.x} ${e.y} ${e.z}`);
            elemento.rotation = e;
            setRotEditing(e);
            setAllowUpdateButton(true);
        }        
    }


    useEffect(() => {        
        setEscena(elemento.escena);
        setTexto(elemento.texto);
        setVideo(elemento.video);
        setTitulo(elemento.titulo);
        setPosEditing(elemento.position);  
        setRotEditing(elemento.rotation); 
    }, [elemento])

    return(<>
        <h1 style={{
            textAlign: 'left'
          }}>{elemento.nombre}</h1>
        <Card title="Posicion: " size="small" style={{
            textAlign: 'left'
          }}>            
            <label>Eje X</label><Slider defaultValue={0} step={0.01} max={100} min={-100} value={posEditing.x} onChangeComplete={()=>guardarCambios(elemento)} onChange={(v) => alCambiarPos({...elemento.position, x:v})} disabled={!elemento}/>
            <label>Eje Y</label><Slider defaultValue={0} step={0.01} max={100} min={-100} value={posEditing.y} onChangeComplete={()=>guardarCambios(elemento)} onChange={(v) => alCambiarPos({...elemento.position, y:v})} disabled={!elemento}/>
            <label>Eje Z</label><Slider defaultValue={0} step={0.01} max={100} min={-100} value={posEditing.z} onChangeComplete={()=>guardarCambios(elemento)} onChange={(v) => alCambiarPos({...elemento.position, z:v})} disabled={!elemento}/>
        </Card>
        <Card title="Rotacion: " size="small" style={{
            textAlign: 'left'
          }}>            
            <label>Eje X</label><Slider defaultValue={0} step={0.01} max={100} min={-100} value={rotEditing.x} onChangeComplete={()=>guardarCambios(elemento)} onChange={(v) => alCambiarRot({...elemento.rotation, x:v})} disabled={!elemento}/>
            <label>Eje Y</label><Slider defaultValue={0} step={0.01} max={100} min={-100} value={rotEditing.y} onChangeComplete={()=>guardarCambios(elemento)} onChange={(v) => alCambiarRot({...elemento.rotation, y:v})} disabled={!elemento}/>            
        </Card>
        <Card title="Accion: " size="small" style={{
            textAlign: 'left'
          }}>
            {elemento && elemento.tipo===1?(<><label>Titulo:{titulo} </label><Input value={titulo} onBlur={()=>guardarCambios(elemento)} onChange={alCambiarTitulo} /></>):null}
            {elemento && elemento.tipo===1?(<><label>Texto:</label><Input value={texto} onBlur={()=>guardarCambios(elemento)} onChange={alCambiarTexto}/></>):null}
            {elemento && elemento.tipo===2?(
              <>
              <label>Escena:</label>
              
              <Select value={escena} onChange={alCambiarEscena} options={proyecto.escenas.map(x => {return {value:x.titulo, label:x.titulo}})} style={{width: '100%'}}/>
              </>):null}
            {elemento && elemento.tipo===0?(<><label>Video URL:</label><Input value={video} onBlur={()=>guardarCambios(elemento)} onChange={alCambiarVideo}/></>):null}             
            {elemento && elemento.tipo===3?(<><label>Imagen:</label><img width="100%" src={modo? imagen: urlImgElement }/></>):null}
            {/* {elemento && elemento.tipo===3 && !modo?(<><label>Imagen de elemento: </label><p>{imagen}</p></>):null} */}
            {elemento && elemento.tipo===3?(<><Upload accept=".png, .jpg" 
                    maxCount={1}
                    beforeUpload={async(file) => {
                        if(modo){
                            const reader = new FileReader();              
                            reader.onload = e => {
                                console.log(e.target.result);
                                alCambiarImagen(e.target.result);
                                guardarCambios(elemento);
                            };
                            reader.readAsDataURL(file);
                        }else{                                                        
                                       
                          try {                                
                                setLoading(true);
                                const tokenVerified = await handleRefreshToken();     

                                //logica que borra imagen anterior si existe
                                if(elemento.imagen){                                  
                                  const dataParaDelete = {
                                    experienceId: proyecto.id,
                                    userId: proyecto.userId,
                                    token: tokenVerified,
                                    fileName: imagen
                                  }
                                  try {
                          
                                    await apiService.deleteFileByName(dataParaDelete);
                                    
                                  } catch (error) {
                                    console.error("Error al borrar imagen anterior", error);
                                  }
                                }


                                //logica que sube la nueva imagen archivo
                                const dataParaPOST = {
                                    sceneId: escenaParaApi.id,
                                     experienceId: proyecto.id,
                                        file: file,
                                        token: tokenVerified,
                                    };        
                                const response = await apiService.postNewFile(                                  
                                  dataParaPOST
                                );                                
                                setImagen(response.file_name);
                                const newUrlimgElement = `${process.env.REACT_APP_BASE_URL}/tour360/uploads/files/${proyecto.userId}/${proyecto.id}/${response.file_name}`;
                                //console.log("%c NUEVA URL DE IMAGEN", "color: green", newUrlimgElement);
                                setUrlImgElement(newUrlimgElement);

                                //cambio el nombre de la imagen en el elemento 
                                elemento.imagen = response.file_name;

                                await alCambiarImagen(response.file_name)
                        
                                if (response) {
                                  setAlertData({
                                    type: "success",
                                    message: "Exito",
                                    description:
                                      "Subida de Archivo Exitosa",
                                  });
                                  setShowAlert(true);
                                }
                              } catch (error) {
                                setAlertData({
                                  type: "error",
                                  message: "Error",
                                  description: "Error al subir un nuevo archivo",
                                });
                                setShowAlert(true);
                              } finally {
                                setLoading(false);                                
                              }
                        }
                      
              
              
                      // Prevent upload
                      return false;
                    }}                    
            ><Button type="primary"  >{elemento.imagen? "Editar Imagen": "Subir Nueva Imagen"}</Button>
            </Upload></>):null}     


             
        </Card>        
        {
            !modo &&(
                <Card size="small" style={{
                    textAlign: 'center'
                }}>
                      <Button
                         ghost
                         type="primary"
                         onClick={handleButtonGuardar}
                         disabled={!allowUpdateButton}
                        >Guardar Cambios a la API</Button>
                    
                </Card>
            )
        }
        
        <Card size="small" style={{
            textAlign: 'center'
          }}>
            <Popconfirm title="Eliminar Elemento"
                        description="Realmente desea eliminar este elemento?"
                        onConfirm={() => eliminarElemento(elemento)}                        
                        okText="Si" cancelText="No"
                        >
                    <Button type="primary"danger>Eliminar</Button>
            </Popconfirm>
        </Card>
        </>)
}
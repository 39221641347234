import React, { useEffect, useState } from "react";
import {
  BarChartOutlined,
  DotChartOutlined,
  LineChartOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Button, Divider, Flex, Radio, Typography } from "antd";
import { AuthContext } from "../../../context/auth-context/AuthContext";
import apiService from "../../../services/apiServices";

export const JumpSelector = ({
  proyecto,
  index,
  setEscena,
  setProyecto,
  escena,
  setSuccess,
  setDataModal,
  setShowModal,
  setLoading,
}) => {
  const [elementosLista, setElementosLista] = useState(
    proyecto.escenas[index].elementos.filter((elemento) => elemento.tipo === 2)
  );
  const [options, setOptions] = useState([]);
  const { handleRefreshToken } = React.useContext(AuthContext);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setElementosLista(
      proyecto.escenas[index].elementos.filter(
        (elemento) => elemento.tipo === 2
      )
    );
    console.log(
      "%c 🚀: elementos en effec de jump ",
      "color: orange; font-size: 16px",
      elementosLista
    );
    if (elementosLista.length > 0) {
      const newOptions = elementosLista.map((elemento, ind) => {
        return {
          value: elemento.id,
          disabled: elemento.escena === "",
          label:
            ind % 2 === 0 ? (
              <Flex gap="small" justify="center" align="center" vertical>
                <LineChartOutlined
                  style={{
                    fontSize: 18,
                  }}
                />
                {elemento.nombre + " " + elemento.escena}
              </Flex>
            ) : (
              <Flex gap="small" justify="center" align="center" vertical>
                <DotChartOutlined
                  style={{
                    fontSize: 18,
                  }}
                />
                {elemento.nombre + " " + elemento.escena}
              </Flex>
            ),
        };
      });
      const elementSetter = proyecto.escenas[index].elementos.find(
        (ele) => ele.tipo === -1
      );
      console.log(
        "%c 🚀: elementos setter en jump ",
        "color: teal; font-size: 16px",
        elementSetter
      );

      if (elementSetter) {
        // const selectOp = elementosLista.findIndex((elemento) => elemento.id === elementSetter.jumpNextSceneId);
        // console.log(
        //   "%c 🚀: SELECTION en jump ",
        //   "color: purple; font-size: 16px",
        //   selectOp
        // );
        setValue(elementSetter.jumpNextSceneId);
      }
      setOptions(newOptions);
    }
  }, [index, proyecto]);

  const onChange = async (e) => {
    setValue(e.target.value);
    console.log(
      "%c e.target.value",
      "color: yellow; font-size:'30px'",
      e.target.value
    );
  };

  const handleSaveJump = async () => {
    //const indiceElementJump = proyecto.escenas[index].elementos[value].id;
    const jumpElement = proyecto.escenas[index].elementos.find(
      (elem) => elem.tipo === -1
    );
    if (jumpElement) {
      jumpElement.jumpNextSceneId = value;
    } else {
      const newElemento = {
        id: "" + Date.now(),
        nombre: "Settings",
        tipo: -1,
        jumpNextSceneId: value,
        position: { x: 18, y: -10, z: -22 },
        rotation: { x: 0, y: 0, z: 0 },
      };
      setEscena({ ...escena, elementos: [...escena.elementos, newElemento] });

      proyecto.escenas[index] = {
        ...proyecto.escenas[index],
        elementos: [...proyecto.escenas[index].elementos, newElemento],
      };
      setProyecto({ ...proyecto });
    }
    console.log(
      "%c elementos despues",
      "color: teal; font-size: 16px",
      proyecto.escenas[index].elementos
    );
    try {
      setLoading(true);
      const elementsDataForPut = {
        elements: [...proyecto.escenas[index].elementos],
      };
      const tokenVerified = await handleRefreshToken();
      await apiService.updateSceneById(
        tokenVerified,
        proyecto.escenas[index].id,
        elementsDataForPut
      );
      setSuccess(true);
      setDataModal({
        titulo: "Jump configurado correctamente",
        mensaje: `felicidades!`,
      });
      setShowModal(true);   
      

      setLoading(false);
    } catch (error) {
      console.log(error);
      setSuccess(false);
      //setError(true);
      setDataModal({
        titulo: "Error al Configurar el Salto de Escena",
        mensaje: "controle los datos por favor...",
      });
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {elementosLista !== null &&
      elementosLista !== undefined &&
      elementosLista.length > 0 ? (
        <Radio.Group onChange={onChange} value={value} options={options} />
      ) : (
        <Typography.Text type="warning">
          No hay elementos para mostrar
        </Typography.Text>
      )}
      <Divider />
      <Button ghost type="primary" onClick={handleSaveJump}>
        Guardar
      </Button>
    </>
  );
};
